import axios from 'axios'
import { message } from '@/util/message'
import settings from './settings.js'
import store from '@/store'


const service = axios.create({
    baseURL: settings.gbs.host, // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 60000, // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
      if (!store.state.login.token) {
        store.dispatch('user/resetToken').then(() => {
          if (window.location.pathname.indexOf('login') > -1) return
          setTimeout(() => { location.reload() }, 100);
        })
      } else {
        config.headers.common.Authorization = store.state.login.token;
      }
      return config
    },
    error => {
        // do something with request error
        if (process.env.NODE_ENV === 'development') {
            console.log(error) // for debug
        }
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    response => {
        const res = response.data
        let config = response.config
      let url = config.url

      if (url.indexOf('downloadZip') > -1) {
        if (res) {
          return response
        } else {
          return Promise.reject(new Error(res.msg || 'Error'))
        }
      } else {
        if (res.code !== 200) {
          message({
            message: res.msg || '请求出错，请重试',
            type: 'error',
            duration: 1500,
            onClose: () => {
              if (url.indexOf('/api/oauth/Login') < 0 && (res.code === 600 || res.code === 601 || res.code === 602)) {
                store.dispatch('user/resetToken').then(() => {
                  if (window.location.pathname.indexOf('login') > -1) return
                  setTimeout(() => { location.reload() }, 100);
                })
              }
            }
          })
          return Promise.reject(new Error(res.msg || 'Error'))
        } else {
          return res
        }
      }
    },
    error => {
        if (process.env.NODE_ENV === 'development') {
            console.log(error) // for debug
        }
        message({
            message: '请求出错，请重试',
            type: 'error',
            duration: 1500
        })
        return Promise.reject(error)
    }
)

export default service
