<template>
  <div ref="container">
  </div>
</template>

<script>
const MAX_PAGE_WIDTH = 100;
const MAX_PAGE_HEIGHT = 200;
const MIN_SPLIT_HEIGHT = 20;
const MAX_LOOP_FACTOR = 5;

export default {
  data() {
    return {
      item: ['短文本项1','短文本项2','短文本项3','短文本项4','短文本项5','短文本项6','短文本项7','短文本项8','短文本项9','短文本项0','短文本项','短文本项','短文本项','短文本项','短文本项','短文本项','短文本项','短文本项','短文本项',1,1,1,1,1,1,1,1,1,1,2,2,3,4,4,5,6,7,8,9,2,2,1],
      allItems: [
        { id: 1, content: '<p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p><p>短文本项</p>' },
        { id: 2, content: '<div>' + '长内容 '.repeat(300) + '</div>' },
        { id: 3, content: '<ul><li>项目A</li><li>项目B</li><li>项目C</li></ul>' }
      ],
      pages: []
    };
  },
  mounted() {
    this.$nextTick(this.calculatePages);
  },
  methods: {
    async calculatePages() {
      try {
        const measuredItems = await this.measureItems();
        // this.pages = this.organizePages(measuredItems);
        // console.log('分页完成，生成页数:', this.pages.length);
      } catch (error) {
        console.error('分页计算失败:', error);
        this.pages = [];
      }
    },

    async measureItems() {
      const container = document.createElement('div');
      console.log(container, 'container')
      container.style.cssText = `
        position: absolute;
        width: ${MAX_PAGE_WIDTH || 100}px;
        height: ${MAX_PAGE_HEIGHT || 100}px;
        padding: 10px
      `;

      this.$refs.container.appendChild(container);
      const p = document.createElement('p');
      p.innerHTML=123

      console.log(container.offsetHeight)
      console.log(container.scrollHeight)
      container.innerHTML = `<p>123</p>`
      console.log(container.offsetHeight)
      console.log(container.scrollHeight)

      /*const measurements = await Promise.all(
        this.allItems.map(async item => {
          const el = document.createElement('div');
          el.className = 'content-segment';
          el.innerHTML = item.content;
          container.appendChild(el);
          await this.$nextTick();
          const height = el.clientHeight;
          container.removeChild(el);
          return { ...item, originalHeight: height };
        })
      );

      document.body.removeChild(container);
      return measurements;*/
    },

    organizePages(items) {
      console.log(items, 'items')
      const pages = [];
      let currentPage = [];
      let currentHeight = 0;
      let queue = [...items];
      let loopCount = 0;
      const maxLoops = items.length * MAX_LOOP_FACTOR;

      while (queue.length > 0 && loopCount++ < maxLoops) {

        const currentItem = queue.shift();

        // 情况1：完整放入当前页
        if (this.canFitItem(currentItem, currentHeight)) {
          console.log('情况1：完整放入当前页')
          currentPage.push(this.createSegment(currentItem));
          currentHeight += currentItem.originalHeight;
          continue;
        }

        // 情况2：尝试拆分
        console.log('情况2：尝试拆分')
        const splitResult = this.safeSplitItem(
          currentItem,
          MAX_PAGE_HEIGHT - currentHeight
        );

        if (splitResult) {
          currentPage.push(splitResult.part1);
          pages.push([...currentPage]);

          // 处理剩余部分
          if (splitResult.part2.originalHeight > 0) {
            queue.unshift(splitResult.part2);
          }

          currentPage = [];
          currentHeight = 0;
        } else {
          // 情况3：强制换页处理（不再重新入队）
          if (currentPage.length > 0) {
            pages.push([...currentPage]);
          }
          pages.push([this.createSegment(currentItem)]);
          currentPage = [];
          currentHeight = 0;
        }
      }

      if (loopCount >= maxLoops) {
        console.warn('安全机制：达到最大循环次数');
        if (currentPage.length > 0) pages.push(currentPage);
      }

      return pages;
    },

    canFitItem(item, currentHeight) {
      return currentHeight + item.originalHeight <= MAX_PAGE_HEIGHT;
    },

    createSegment(item, content = item.content) {
      return {
        ...item,
        uid: this.generateUID(),
        content,
        originalHeight: item.originalHeight
      };
    },

    safeSplitItem(item, availableHeight) {
      // 新增分割前校验
      if (availableHeight < MIN_SPLIT_HEIGHT) return null;
      if (item.originalHeight <= availableHeight) return null;

      const parser = new DOMParser();
      const doc = parser.parseFromString(
        `<div>${item.content}</div>`,
        'text/html'
      );
      const root = doc.body.firstChild;

      let accumulatedHeight = 0;
      let splitNode = null;
      const nodeQueue = Array.from(root.childNodes);

      // 增强的广度优先搜索
      while (nodeQueue.length > 0) {
        const node = nodeQueue.shift();
        const tempEl = this.createTestElement(node.outerHTML);
        const height = tempEl.clientHeight;
        document.body.removeChild(tempEl);

        // 关键改进：提前终止条件
        if (height > MAX_PAGE_HEIGHT) return null;

        if (accumulatedHeight + height > availableHeight) {
          splitNode = node;
          break;
        }

        accumulatedHeight += height;
        if (node.childNodes) {
          nodeQueue.push(...Array.from(node.childNodes));
        }
      }

      if (!splitNode) return null;

      const part1Content = this.getHtmlBeforeNode(root, splitNode);
      const part2Content = this.getHtmlFromNode(splitNode);

      // 严格验证拆分结果
      const part1 = this.createSegment(item, part1Content);
      const part2 = this.createSegment(item, part2Content);
      part1.originalHeight = this.measureHtmlHeight(part1Content);
      part2.originalHeight = this.measureHtmlHeight(part2Content);

      const isValidSplit =
        part1.originalHeight <= availableHeight &&
        part2.originalHeight > 0 &&
        part2.originalHeight <= MAX_PAGE_HEIGHT && // 新增关键校验
        part1.originalHeight + part2.originalHeight > item.originalHeight * 0.8;

      return isValidSplit ? { part1, part2 } : null;
    },

    getHtmlBeforeNode(root, stopNode) {
      const fragment = [];
      const walker = document.createTreeWalker(
        root,
        NodeFilter.SHOW_ALL,
        null
      );

      while (walker.nextNode()) {
        const node = walker.currentNode;
        if (node === stopNode) break;
        fragment.push(node.cloneNode(true).outerHTML);
      }

      return fragment.join('');
    },
    getHtmlFromNode(startNode) {
      const fragment = [];
      const walker = document.createTreeWalker(
        startNode.parentNode,
        NodeFilter.SHOW_ALL,
        null,
        { currentNode: startNode }
      );

      while (walker.nextNode()) {
        fragment.push(walker.currentNode.cloneNode(true).outerHTML);
      }

      return fragment.join('');
    },
    createTestElement(html) {
      const el = document.createElement('div');
      el.className = 'content-segment';
      el.style.cssText = `
        position: absolute;
        visibility: hidden;
        width: ${this.$el?.clientWidth || 500}px;
      `;
      el.innerHTML = html;
      document.body.appendChild(el);
      return el;
    },
    measureHtmlHeight(html) {
      const el = this.createTestElement(html);
      const height = el.clientHeight;
      document.body.removeChild(el);
      return height;
    },

    generateUID() {
      return `${Date.now()}-${Math.random().toString(36).slice(2, 11)}`;
    }
    // ...（其他工具方法保持不变）...
  }
};
</script>
<style>
.page-container {
  height: 200px;
  border: 1px solid #e0e0e0;
  margin: 12px 0;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  page-break-inside: avoid; /* 打印友好 */
}

.content-segment {
  padding: 8px 12px;
  line-height: 1.6;
  font-family: -apple-system, system-ui, sans-serif;
  font-size: 14px;
  color: #333;
  orphans: 2; /* 防止单个单词单独成行 */
}

.content-segment >>> img {
  max-width: 100%; /* 图片自适应 */
}
</style>
