import request from '@/config/request.js'
import pdf from 'vue-pdf'
import settings from '@/config/settings.js'
import QRCode from 'qrcodejs2'
import common from '@/components/common/'
import moment from 'moment'
const ApiEvaluation = request.Evaluation
export default {
  name: 'evaluation-index',
  components: {
    ...common,
    pdf
  },
  data () {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码！'))
      }
      setTimeout(() => {
        var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的手机号码'))
        } else {
          callback()
        }
      }, 1000)
    }
    return {
      search_data: {
        studentName: ''
      },
      // 需要给分页组件传的信息
      formLabelWidth: '140px',
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      userList: [],
      imgView: false,
      imageList: [],
      pageNum: 1,
      pageTotalNum: 1,
      loadedRatio: 0,
      zoneId: this.$store.state.login.zoneId,
      schoolsDialog: false,
      editDialog: false,
      details: {
        linkName: '',
        mobile: '',
        zoneId: this.$store.state.login.zoneId
      },
      edit: {
        linkName: '',
        mobile: '',
        zoneId: this.$store.state.login.zoneId
      },
      detailsRules: {
        linkName: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, validator: checkPhone, trigger: 'blur' }
        ]
      },
      detailList: false,
      userInfo: JSON.parse(this.$store.state.login.userInfo),
      titleReport: {
        reportNum: 0,
        userNum: 0
      }
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 355
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    getTimeString (str) {
      const dateTime = moment(str);
// 输出年月日时分秒
//       console.log(dateTime.format('YYYY-MM-DD HH:mm:ss'));
      return dateTime.format('YYYY-MM-DD HH:mm:ss')
    },
    onDelete (row) {
      this.$confirm('确认删除该测评数据吗?删除数据不可恢复，是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          userId: row.userId
        }
        ApiEvaluation.deleteUser.call(this, data, (listData) => {
          this.initData()
        })
        /*ApiclassManagement.removeOnlineClassById.call(this, data, () => {
          this.getList()
        })*/
      }).catch(() => {
      })
    },
    down () {
      if (this.$store.state.login.zoneId) {
        var canvasData = this.$refs.qrCodeUrl.getElementsByTagName('canvas')
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.href = canvasData[0].toDataURL('image/png')
        a.download = this.userInfo.zoneName ? this.userInfo.zoneName : 'drcQrcode'
        a.dispatchEvent(event)
      } else {
        this.$message({
          type: 'error',
          message: '二维码下载失败，校区错误！'
        })
      }
    },
    editDailogShow () {
      this.edit = Object.assign(this.edit, this.details)
      this.editDialog = true
    },
    dailogShow () {
      this.schoolsDialog = true
    },
    addDetails (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiEvaluation.addLinkman.call(this, this.details, (item) => {
            this.$message({
              message: '添加成功！',
              type: 'success'
            })
            this.findLinkman()
            this.schoolsDialog = false
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    editDetails (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiEvaluation.updateLinkman.call(this, this.edit, (item) => {
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
            this.findLinkman()
            this.editDialog = false
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    /**
     * 搜索事件
     */
    onSearch () {
      // this.search_data.current_page = 1
      this.initData()
    },
    // 上一页
    prePage () {
      let page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      this.pageNum = page
    },
    // 下一页
    nextPage () {
      let page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      this.pageNum = page
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    getShowDetails (id) {
      ApiEvaluation.findReportByUserId.call(this, { userId: id }, (listData) => {
        if (listData.length < 1) {
          this.$message({
            message: '该用户没有产生报告！'
          })
          return
        }
        listData.map(item => {
          item.reportPath = settings.gbs.host + item.reportPath
          window.open(item.reportPath)
        })
      })
    },
    getParentReport (id) {
      ApiEvaluation.findParentReport.call(this, { wechatId: id }, (listData) => {
        if (!listData.reportPath) {
          this.$message({
            message: '该用户没有产生报告！'
          })
          return
        }
        window.open(settings.gbs.host + listData.reportPath)
      })
    },
    deleteParentReport (id) {
      this.$confirm('是否确定删除该报告？删除后不可恢复。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.deleteWechatUser.call(this, { wechatId: id, ajaxType: 'form' }, (listData) => {
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          this.findWechatUser()
        })
      }).catch(() => {
      })
    },
    initData () {
      this.listLoading = true;
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: this.search_data,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'studentId'
      }
      // let search = {
      //   filters: []
      // }
      // this.search_data.filters.map(item => {
      //   if (item.value !== '') {
      //     search.filters.push(item)
      //   }
      // })
      let _query = {
        ...data
      };
      ApiEvaluation.getPageAppraisalUser.call(this, _query, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
      })
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var data = {}
      data = Object.assign(data, {})
      ApiEvaluation.findBySchool.call(this, data, (listData) => {
        this.tableList = listData
      })
    },
    findWechatUser ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var data = {}
      data = Object.assign(data, {})
      ApiEvaluation.findWechatUser.call(this, data, (listData) => {
        this.userList = listData
      })
    },
    creatQrCode () {
      if (this.$store.state.login.zoneId) {
        const url = settings.gbs.code + 'zoneId=' + this.$store.state.login.zoneId + '&type=angelbell'
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url,
          width: 75,
          height: 75,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
        console.log(qrcode)
      }
    },
    findLinkman () {
      ApiEvaluation.findLinkman.call(this, {}, (listData) => {
        if (listData.length > 0) {
          this.details = listData[0]
          this.detailList = true
          this.edit.linkmanId = listData[0].linkmanId
        }
      })
    },
    findStudentAndReportNum () {
      ApiEvaluation.findStudentAndReportNum.call(this, {}, (listData) => {
        this.titleReport = Object.assign(this.titleReport, listData)
      })
    }
  },
  mounted () {
    this.initData()
    this.findWechatUser()
    this.creatQrCode()
    this.findLinkman()
    this.findStudentAndReportNum()
  }
}
