import request from '@/config/requestOther'

export function downloadFileAll (data, type) {
    return request({
        url: `app/rest/school/auth/fileManagerNew/downloadZip`,
        method: 'post',
        data,
        responseType: type.responseType
    })
}
